

* { box-sizing: border-box; }
.video-background {
  background: #000;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
}
.tab-container {
	overflow-x: hidden;
	white-space: nowrap;
}

.nav-tabs {
	bottom: 0px;
	position: absolute;
	width: 100%;
	left: 0;
	margin-right: -10px;
}
a{
	color:white !important;
}


.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#vidtop-content {
	top: 0;
	color: #fff;
}
.vid-info { position: absolute; top: 0; right: 0; width: 33%; background: rgba(0,0,0,0.3); color: #fff; padding: 1rem; font-family: Avenir, Helvetica, sans-serif; }
.vid-info h1 { font-size: 2rem; font-weight: 700; margin-top: 0; line-height: 1.2; }
.vid-info a { display: block; color: #fff; text-decoration: none; background: rgba(0,0,0,0.5); transition: .6s background; border-bottom: none; margin: 1rem auto; text-align: center; }
@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
}
@media all and (max-width: 600px) {
.vid-info { width: 50%; padding: .5rem; }
.vid-info h1 { margin-bottom: .2rem; }
}
@media all and (max-width: 500px) {
.vid-info .acronym { display: none; }
}

@keyframes seq-preloader {
  50% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
}

.seq-preloader {
  background: white;
  visibility: visible;
  opacity: 1;
  position: absolute;
  z-index: 9999;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.seq-preloader.seq-preloaded {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s .5s, opacity .5s;
}

.seq-preload-indicator {
  overflow: visible;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.seq-preload-circle {
  display: inline-block;
  height: 12px;
  width: 12px;
  fill: #7a7a7a;
  opacity: 0;
  animation: seq-preloader 1.25s infinite;
}

.seq-preload-circle-2 {
  animation-delay: .15s;
}

.seq-preload-circle-3 {
  animation-delay: .3s;
}

.seq-preload-indicator-fallback {
  width: 42px;
  overflow: visible;
}

.seq-preload-indicator-fallback .seq-preload-circle {
  width: 8px;
  height:8px;
  background-color: #f4f4f4;
  border-radius: 100%;
  opacity: 1;
  display: inline-block;
  vertical-align: middle;
}

.seq-preload-indicator-fallback .seq-preload-circle-2 {
  margin-left: 3px;
  margin-right: 3px;
  width: 12px;
  height: 12px;
}

.seq-preload-indicator-fallback .seq-preload-circle-3 {
  width: 16px;
  height: 16px;
}


/* MAIN CSS */

@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #fff;
  }
}
@keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #fff;
  }
}
@-webkit-keyframes fillColour {
  0% {
    color: #fff;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #333;
  }
}
@keyframes fillColour {
  0% {
    color: #fff;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #333;
  }
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html {
	overflow: hidden;
}

body {
	height: 100%;
  overflow-y: scroll;
  font-size: 100%;
}

nav {
	top: 10vh;
	bottom: 10vh;
  	text-align: center;
  	background: rgba(0, 0, 0, 0.5);
  	position: fixed;
  	z-index: 100;
  	height: 68vh;
  	left: 0;
  	width: 20%;
  	font-weight: 300;
  	font-size: 1rem;
	border-radius: 15px;
}

nav em {
	font-style: normal;
	margin-left: 5px;
	font-size: 14px;
}

nav ul li i {
	width: 25px;
}

nav ul li a {
	font-size: 18px;
}

nav .mini-logo {
	display: none;
}

nav .logo {
	margin-top: 45px;
	padding: 0px 20px;
}

nav .logo img {
	max-width: 100%;
	overflow-y: hidden;
}

nav ul {
	margin-top: 60px;
  	text-align: left;
  	margin-left: 40px;
  	list-style-type: none;
  	height: 48vh;
  	display: -webkit-box;
  	display: -ms-flexbox;
  	display: flex;
  	-webkit-box-orient: vertical;
  	-webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  	-ms-flex-pack: distribute;
    justify-content: space-around;
}
nav ul li {
	margin: 25px 0px;
}
nav a, nav a:visited, nav a:active {
  color: #fff;
}
nav a {
	text-decoration: none!important;
	font-size: 14px;
	letter-spacing: 0.5px;
  	-webkit-transition: color 2s, background 1s;
  	transition: color 2s, background 1s;
  	padding: 20px 30px;
  	position: relative;
  	z-index: 0;
}
nav a.active {
  background-color: #fff;
  color: #232323;
}
nav a::before {
  content: "";
  position: absolute;
  height: 0%;
  width: 0%;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: -1;
}
* {
	scroll-behavior: smooth;
}
nav a:hover {
	text-decoration: none;
  -webkit-animation: fillColour 0.7s forwards ease-in-out;
          animation: fillColour 0.7s forwards ease-in-out;
}
nav a:hover::before {
  -webkit-animation: fill 0.7s forwards ease-in-out;
          animation: fill 0.7s forwards ease-in-out;
  opacity: 1;
}

.slides {
  width: 300vw;
  height: 100vh;
  -webkit-transition: -webkit-transform 0.8s ease;
  transition: -webkit-transform 0.8s ease;
  transition: transform 0.8s ease;
  transition: transform 0.8s ease, -webkit-transform 0.8s ease;
}
.slides .slide {
  height: 100vh;
  width: 100vw;
  float: left;
  text-align: center;
  background-size: cover;
}
.slides .slide .content {
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  width: 70%;
  height: 80%;
  left: 15.5%;
  border-radius: 15px;
  top: 10%;
  bottom: 10%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  padding-top:30px
}
.content::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.5);
}
/* Add a thumb */
.content::-webkit-scrollbar-thumb {
    background: rgba(250, 250, 250, 0.5);
}



.main-btn a:hover  {
	background-color: #fff;
	color: #232323 !important;
}

.tab-container a:hover {
	background-color: #fff;
	color: #232323 !important;
	transition: all 0.5s;
}
.tab-container {
	transition: all 0.5s;
	transition: all 0.5s;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
	text-decoration: none;
}
.tab-container .a {
	color:white;
}
.content.first-content {
	position: relative;
	overflow:hidden;
}

.first-content {
	padding: 100px;
	text-align: left;
	padding-top: 8%;
	margin-top:-120px;
}

.first-content .author-image img {
	
	border-radius: 30px;
	max-width: 100%;
	overflow: hidden;
	float: left;
	margin-right: 35px;
}

.first-content h2 {
	font-size: 36px;
	text-transform: uppercase;
	font-weight: 700;
	color: #fff;
	letter-spacing: 0.5px;
	border-bottom: 2px solid #fff;
	display: inline-block;
	padding-bottom: 10px;
	margin-bottom: 20px;
	margin-top: 0px;
}

.first-content p {
	color: #fff;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 300;
	letter-spacing: 2px;
	line-height: 26px;
	margin-bottom: 20px;
}

.first-content em {
	font-style: normal;
	font-weight: 600;
}

.first-content .main-btn {
	display: inline-flex;
	margin-right: 15px;
}

.first-content .main-btn a {
	display: inline-block;
	border: 2px solid #fff;
	padding: 8px 15px;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
	text-decoration: none;
	transition: all 0.5s;
	transition: all 0.5s;
}

.first-content .fb-btn {
	display: inline-block;
}

.first-content .fb-btn a {
	display: inline-block;
	background-color: #fff;
	border: 2px solid #fff;
	padding: 8px 15px;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #232323;
	text-decoration: none;
	transition: all 0.5s;
}

.first-content .fb-btn a:hover {
	color: #fff;
	background-color: transparent;
}


.second-content .container-fluid {
	padding-right: 0px;
	padding-left: 0px;
}

.second-content .container-fluid .col-md-6 {
	padding-right: 0px;
	padding-left: 0px;
}

.second-content .right-image img {
	max-width: 100%;
	min-width: 100%;
	overflow: hidden;
	float: right;
}

.second-content .left-content {
	padding: 60px;
	text-align: left;
}

.second-content h2 {
	font-size: 36px;
	text-transform: uppercase;
	font-weight: 700;
	color: #fff;
	letter-spacing: 0.5px;
	border-bottom: 2px solid #fff;
	display: inline-block;
	padding-bottom: 10px;
	margin-bottom: 20px;
	margin-top: 0px;
}

.second-content p {
	font-size: 13px;
	color: #fff;
	letter-spacing: 0.5px;
	font-weight: 300;
	line-height: 24px;
	margin-bottom: 30px;
}

.second-content a {
	color: #fff;
	text-decoration: underline;
}

.second-content .main-btn a {
	display: inline-block;
	border: 2px solid #fff;
	padding: 8px 15px;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
	text-decoration: none;
	transition: all 0.5s;
}


.third-content {
	text-align: left;
	padding: 30px;
}

.third-content .container-fluid {
	padding-right: 7.5px;
	padding-left: 7.5px;
}

.third-content .first-section .left-content {
	margin-right: 15px;
	margin-top: 12px;
}

.third-content .first-section .right-image {
	margin-left: 15px;
}

.third-content .second-section {
	margin-top: 30px;
}

.third-content .second-section .right-content {
	margin-left: 15px;
	margin-top: 12px;
}

.third-content .second-section .left-image {
	margin-right: 15px;
}

.third-content h2 {
	font-size: 24px;
	text-transform: uppercase;
	font-weight: 700;
	color: #fff;
	letter-spacing: 0.5px;
	border-bottom: 2px solid #fff;
	display: inline-block;
	padding-bottom: 10px;
	margin-bottom: 20px;
	margin-top: 0px;
}

.third-content p {
	font-size: 13px;
	color: #fff;
	letter-spacing: 0.5px;
	font-weight: 300;
	line-height: 24px;
	margin-bottom: 20px;
}

.third-content .main-btn a {
	display: inline-block;
	border: 2px solid #fff;
	padding: 8px 15px;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
	text-decoration: none;
	transition: all 0.5s;
}

.third-content .col-md-6 {
	margin: 0px;
	padding: 0px;
}


.fourth-content {
	padding: 5px;
}

.fourth-content .container-fluid {
	padding-right: 15px;
	padding-left: 15px;
}

.fourth-content .container-fluid .col-md-4 {
	padding-right: 0px;
	padding-left: 0px;
}

.fourth-content .item {
	margin: 10px;
}

.fourth-content .item img {
	max-width: 100%;
	overflow: hidden;
}

.fourth-content .item .thumb {
	position: relative;
}

.fourth-content .item .hover-effect {
	text-align: left;
	position: absolute;
	color: #fff;
	background-color: rgba(0,0,0,0.95);
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	opacity: 0;
	visibility: hidden;
	transition: all 0.8s;
}

.fourth-content .item:hover .hover-effect {
	opacity: 1;
	visibility: visible;
}

.fourth-content .item .hover-effect .hover-content {
	transform: translateY(-50%);
    position: absolute;
    text-align: left;
    width: 100%;
    top: 50%;
    left: 0;
    padding: 30px;
}

.fourth-content .item .hover-effect h2 {
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 700;
	color: #fff;
	letter-spacing: 0.5px;
	border-bottom: 1px solid #fff;
	display: inline-block;
	padding-bottom: 10px;
	margin-bottom: 10px;
	margin-top: 0px;
}

.fourth-content .item .hover-effect p {
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	line-height: 22px;
	padding-bottom: 0px;
	margin-bottom: 0px;
}



.fifth-content .container-fluid {
	padding-left: 0px;
	padding-right: 0px;
}

.fifth-content .container-fluid .col-md-6 {
	padding-left: 0px;
	padding-right: 0px;
}

.fifth-content #map {
	padding: 50px 0px 50px 50px;
}

.fifth-content #contact {
	padding: 50px 50px 50px 50px;
}

.fifth-content input {
  border-radius: 0px;
  padding-left: 15px;
  font-size: 13px;
  font-weight: 300;
  color: #fff;
  background-color: rgba(250, 250, 250, 0.15);
  outline: none;
  border: none;
  box-shadow: none;
  line-height: 40px;
  height: 40px;
  width: 100%;
  margin-bottom: 25px;
}

.fifth-content textarea {
  border-radius: 0px;
  padding-left: 15px;
  padding-top: 10px;
  font-size: 13px;
  font-weight: 300;
  color: #fff;
  background-color: rgba(250, 250, 250, 0.15);
  outline: none;
  border: none;
  box-shadow: none;
  height: 165px;
  max-height: 220px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 25px;
}

.fifth-content button {
	max-width: 100%;
	width: 100%;
    display: inline-block;
	border: 2px solid #fff;
	padding: 8px 15px;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
	text-decoration: none;
	background-color: transparent;
	border-radius: 0px;
	transition: all 0.5s;
}

.fifth-content button:hover {
	color: #232323;
	background-color: #fff;
}

.footer .content {
	left: 30%;
	width: 70%;
	max-width: 70%;
	position: absolute;
	bottom: 0;
	right: 0;
	display: inline-block;
}

.footer .content p {
	text-align: center;
	font-size: 13px;
	color: #fff;
	margin-bottom: 20px;
}

.footer .content p a {
	color: #aaa;
	text-decoration: none;
}



/* REPONSIVE */


@media (max-width: 768px) {

	.content .first-content{
		overflow-y: scroll !important;
		padding-bottom:0px !important;
	}

	.nav .nav-tabs{
		bottom: 0px !important;
    	position: relative !important;
	}

	.slides .slide .content{
		overflow-y: scroll;
  		overflow-x: hidden;
  		position: relative;
  		width: 70%;
  		left: 15.5%;
  		border-radius: 15px;
  		top: 20%;
  		bottom: 20%;
  		background: rgba(0, 0, 0, 0.5);
  		backdrop-filter: blur(10px);
  		padding-top:30px
	}



}









@media (max-width: 991px) {

	.nav .nav-tabs{
		bottom: 0px !important;
    	position: relative !important;
	}
	nav ul {
  		margin-left: 25px;
  	}

	.first-content {
		text-align: center;
		padding: 50px;
		
	}

	.first-content .author-image img {
		max-width: 195px;
		float: none;
		margin-right: 0px;
		margin-bottom: 30px;
	}

	.third-content .first-section {
		border-bottom: 1px solid rgba(250,250,250,0.3);
		padding-bottom: 45px;
		margin-bottom: 45px;
	}

	.third-content .first-section .left-content {
		margin-right: 0px;
		margin-bottom: 30px;
	}

	.third-content .first-section .right-image {
		margin-left: 0px;
	}

	.third-content .second-section .right-content {
		margin-left: 0px;
		margin-top: 30px;
	}


	.third-content .second-section .left-image {
		margin-right: 0px;
	}

	.fifth-content #map {
		padding: 30px 30px 30px 30px;
	}

	.fifth-content #contact {
		padding: 30px 30px 30px 30px;
	}

}

@media (max-width: 749px) {

	.nav .nav-tabs{
		bottom: 0px !important;
    	position: relative !important;
	}
	nav {
		width: 15vh;
	}

	nav .logo {
		display: none;
	}

	nav .mini-logo {
		margin-top: 60px;
		display: block;
	}

	nav ul {
		text-align: center;
		margin-left: 0px;
	}


	nav ul li em {
		display: none;
	}

	nav ul li i {
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;
	}

	.first-content {
		padding: 30px 5px;
	}

	.first-content .main-btn {
		display: inline-block;
		margin-right: 0px;
		margin-bottom: 15px;
	}

	.second-content .left-content {
		padding: 30px;
		text-align: left;
	}

	.fourth-content .item .hover-effect h2 {
		font-size: 15px;
	}


	.fifth-content #map {
		padding: 15px 15px 15px 15px;
	}

	.fifth-content #contact {
		padding: 15px 15px 15px 15px;
	}

}

@media(max-width: 450px) {
	.nav .nav-tabs{
		bottom: 0px !important;
    	position: relative !important;
	}
	nav ul {
		margin-top: 30px;
	}
	nav ul li {
		margin: 10px 0px;
	}
	nav a {
	  	padding: 15px 20px;
	}
}
body:after {
  /* content: url(../img/close.png) url(../img/loading.gif) url(../img/prev.png) url(../img/next.png); */
  display: none
}

body.lb-disable-scrolling {
  overflow-y: scroll
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: #000;
  filter: alpha(opacity=80);
  opacity: .8;
  display: none
}

.lightbox {
  position: absolute;
  margin-top: 0%;
  left: 0;
  width: 100%;
  z-index: 100000;
  text-align: center;
  line-height: 0;
  font-weight: 400
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 5px;
  border: 10px solid #fff
}

.lightbox a img {
  border: none
}

.lb-outerContainer {
  position: relative;
  zoom:1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: #fff
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  /* background: url(../img/loading.gif) no-repeat */
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10
}

.lb-container>.nav {
  left: 0
}

.lb-nav a {
  outline: none;
  background-image: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==)
}

.lb-prev,.lb-next {
  height: 100%;
  cursor: pointer;
  display: block
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  /* background: url(../img/prev.png) left 48% no-repeat; */
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s
}

.lb-nav a.lb-prev:hover {
  filter: alpha(opacity=100);
  opacity: 1
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  /* background: url(../img/next.png) right 48% no-repeat; */
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s
}

.lb-nav a.lb-next:hover {
  filter: alpha(opacity=100);
  opacity: 1
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  zoom:1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both
}

.lb-data {
  padding: 0 4px;
  color: #ccc
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: 700;
  line-height: 1em
}

.lb-data .lb-caption a {
  color: #4ae
}

.lb-data .lb-number {
  margin-top: 15px;
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 13px;
  color: #999
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  /* background: url(../img/close.png) top right no-repeat; */
  text-align: right;
  outline: none;
  filter: alpha(opacity=70);
  opacity: .7;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: alpha(opacity=100);
  opacity: 1
}
