.player_container
{
    width: 70.5%;
    height: fit-content;
    left:15.5%;
    position: relative;
    padding: 1rem;
    border: 1px solid rgb(141, 141, 141);
    border-radius: 10px;
    color: rgb(218, 218, 218);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    margin-bottom:30px;

    >*
    {
        padding: 1rem 0;
        color: rgb(202, 202, 202);
    }

    .title{
        font-size: inherit;
    }
    .navigation{
        width: 100%;


        .navigation_wrapper
        {
            min-width: 100%;
            background-color: rgba(119, 119, 119, 0.781);
            height: 5px;
            border-radius: 30px;
            cursor: pointer;
            
            .seek_bar{
                width: 0;
                height: 100%;
                background-color: rgb(24, 128, 3);
                border-radius: 30px;
            }

        }
    }
    .controls{
        font-size: inherit;
        display: flex;
        align-items: center;

        .btn_action
        {
            font-size: 2rem;
            margin: 0 10px;
            cursor: pointer;

            &:hover{
            color: white;
        }

        }
        
        .pp
        {
            font-size: 4rem;
            
        }
        
    }
    // @media (max-width: 661px){
    //     .player_container{
    //         .controls{
    //             .btn_action {
    //                 margin: 0 10px !important;
    //             }
    //         }
    //     }
    // }
}